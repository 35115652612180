<template>
  <div>
      dashboard, coming soon
  </div>
</template>

<script>
export default {

}
</script>